<template>
  <v-card
      class="mx-auto my-12 text-center"
      max-width="374"
  >
    <v-card-title class="justify-center green--text">Congratulations!</v-card-title>
    <v-card-text>
      <div class="subtitle-1">
        Your purchase is successful.
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
          rounded
          color="deep-purple lighten-2"
          text
          @click="$router.push({name: 'Course',params:{courseId:$route.params.courseId}})"
      >
        Go to your course
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Success"
}
</script>

<style scoped>

</style>